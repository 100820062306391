<template>
  <!-- <v-btn-toggle color="primary" rounded mandatory class="video-button-group"> -->
  <div class="video-button-group mt-3 mt-md-0">
    <v-btn color="primary" rounded elevation="0" @click="toggleMyAudioActive()">
      <template v-if="audioEnabled">
        <v-icon color="white" centered>mdi-microphone</v-icon>
      </template>
      <template v-else>
        <v-icon color="danger" centered>mdi-microphone-off</v-icon>
      </template>
    </v-btn>
    <v-btn
      color="primary"
      rounded
      elevation="0"
      class="mx-3"
      @click="toggleMyVideoActive"
    >
      <template v-if="videoEnabled">
        <v-icon color="white" centered>mdi-video</v-icon>
      </template>
      <template v-else>
        <v-icon color="danger" centered>mdi-video-off</v-icon>
      </template>
    </v-btn>
    <v-btn
      color="primary"
      rounded
      elevation="0"
      class="me-3"
      @click="openSettingsDialog"
    >
      <v-icon>mdi-cog</v-icon>
    </v-btn>

    <v-btn
      v-if="leaveButton"
      rounded
      elevation="0"
      color="danger"
      @click="leaveRoom"
    >
      <template>
        <v-icon color="white" centered>mdi-phone-hangup</v-icon>
      </template>
    </v-btn>
    <SettingsDialog ref="settingsDialogRef" />
  </div>

  <!-- </v-btn-toggle> -->
</template>

<script>
// import AgoraMixin from "@/mixins/AgoraMixin";
import SettingsDialog from "@/components/Video/SettingsDialog";
import TwilioMixin from "@/mixins/TwilioMixin";

export default {
  props: {
    leaveButton: {
      type: Boolean,
      default: false,
    },
  },

  name: "VideoCallButtons",
  components: {
    SettingsDialog,
  },
  mixins: [TwilioMixin],

  methods: {
    leaveRoom() {
      this.$emit("onLeave");
    },

    openSettingsDialog() {
      this.$refs.settingsDialogRef.open();
    },
  },
};
</script>

<style scoped></style>
