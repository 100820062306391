<template>
  <!-- <v-btn-toggle color="primary" rounded mandatory class="video-button-group"> -->
  <div class="video-button-group mx-auto mt-3 mt-md-0">
    <v-btn color="primary" rounded elevation="0" @click="toggleAudioActive">
      <template v-if="microphoneEnabled">
        <v-icon color="white" centered>mdi-microphone</v-icon>
      </template>
      <template v-else>
        <v-icon color="danger" centered>mdi-microphone-off</v-icon>
      </template>
    </v-btn>

    <v-btn
      color="primary"
      rounded
      elevation="0"
      class="mx-3"
      @click="toggleVideoActive"
    >
      <template v-if="videoEnabled">
        <v-icon color="white" centered>mdi-video</v-icon>
      </template>
      <template v-else>
        <v-icon color="danger" centered>mdi-video-off</v-icon>
      </template>
    </v-btn>

    <v-btn
      color="primary"
      rounded
      elevation="0"
      class="me-3"
      @click="openSettingsDialog"
    >
      <v-icon>mdi-cog</v-icon>
    </v-btn>

    <v-btn
      v-if="leaveButton"
      rounded
      elevation="0"
      color="danger"
      @click="leaveRoom"
    >
      <template>
        <v-icon color="white" centered>mdi-phone-hangup</v-icon>
      </template>
    </v-btn>
    <SettingsDialogZoom
        ref="settingsDialogRef"

        :videoTrack="videoTrack"
        :switchCamera="switchCamera"
        :activeCamera="activeCamera"

        :audioTrack="audioTrack"
        :activeMicrophone="activeMicrophone"
        :switchMicrophone="switchMicrophone"
        :microphoneEnabled="microphoneEnabled"
    />
  </div>

  <!-- </v-btn-toggle> -->
</template>

<script>
import ZoomMixin from "@/mixins/ZoomMixin";
import SettingsDialogZoom from "@/components/Video/zoom/SettingsDialogZoom.vue";

export default {
  props: {
    leaveButton: {
      type: Boolean,
      default: false,
    },

    videoEnabled: {},
    switchCamera: {},
    videoTrack: {},
    activeCamera: {},

    microphoneEnabled: {},
    activeMicrophone: {},
    audioTrack: {},
    switchMicrophone: {},
  },

  name: "VideoCallButtonsZoom",
  components: {
    SettingsDialogZoom,
  },
  mixins: [ZoomMixin],

  methods: {

    toggleVideoActive() {
      this.$emit('toggleVideoActive')
    },

    toggleAudioActive() {
      this.$emit('toggleAudioActive')
    },

    leaveRoom() {
      this.$emit("onLeave");
    },

    openSettingsDialog() {
      this.$refs.settingsDialogRef.open();
    },
  },

};
</script>

<style scoped></style>
