<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="secondary" large block v-bind="attrs" v-on="on">
          <v-icon left small>mdi-cog</v-icon>
          {{ $t("Test Video / Audio") }}
        </v-btn>
      </template>

      <v-card>
        <v-btn
          @click="dialog = false"
          elevation="2"
          icon
          class="close-icon pa-0"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <PreVideoCall style="width: 100%" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PreVideoCall from "@/components/Video/PreVideoCall";
import TwilioMixin from "@/mixins/TwilioMixin";
// import AgoraMixin from "@/mixins/AgoraMixin";

export default {
  components: {
    PreVideoCall,
  },
  mixins: [
    // AgoraMixin,
    TwilioMixin,
  ],

  data() {
    return {
      dialog: false,
    };
  },

  beforeDestroy() {
    this.removeVideoStream();
  },
};
</script>
