<template>
  <div ref="videoPlace" class="d-flex flex-column w-100">
    <v-btn v-if="paused" color="primary" @click="playVideo">
      Tap To Play
    </v-btn>

    <!--    <div class="backdrop z-index-1">-->
    <!--      <div class="username">-->
    <!--{{ user }}-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
// import AgoraRTC from "agora-rtc-sdk-ng"

export default {
  name: "VideoItem",

  data: () => {
    return {
      videoElement: null,
      paused: false,
      playerContainer: null,
      currentTracks: [],
    };
  },

  props: {
    // stream: {
    //   required: true
    // },

    videoTrack: {
      required: false,
      default: null,
    },

    participant: {
      required: false,
      default: null,
    },

    audioTrack: {
      required: false,
      default: null,
    },

    // user: {
    //   required: true
    // },

    // muted: {
    //   type: Boolean,
    //   default: false
    // }
  },

  methods: {
    playVideo() {
      this.paused = false;
      // this?.videoElement?.play() || console.error('No Video')
      // this.paused = this.videoElement.paused;
    },

    createVideoElement1() {
      let video = (this.videoElement = document.createElement("video"));
      video.muted = this.muted;
      video.autoplay = true;
      video.controls = false;

      video.srcObject = this.stream;
      // video.playsinline = true;
      video.setAttribute("playsinline", "");
      video.setAttribute("webkit-playsinline", "");
      video.style.objectFit = "cover";
      video.classList.add("rounded", "video-item");
      // video.width = 300;
      // console.log("this.stream: ", this.stream);

      // window.test = this.stream;

      this.$refs.videoPlace.appendChild(video);

      video.addEventListener("loadedmetadata", () => {
        video.play();
        // this.$refs.videoPlace.appendChild(video);
        this.paused = video.paused;
      });

      video.addEventListener("pause", () => {
        this.paused = video.paused;
      });

      video.addEventListener("play", () => {
        this.paused = video.paused;
      });
    },

    // getUserTrack(twilioTrack) {
    //   if(!twilioTrack) return null;
    //
    //   let tracks = Array.from(twilioTrack.values());
    //   return tracks[0].track
    // },

    attachTrack(track) {
      // this.playerContainer.innerHTML = '';
      // let videoTrack = this.videoTrack;
      // let audioTrack = this.audioTrack;
      // if(videoTrack) {

      let sameKindTracks = this.currentTracks.filter(
        (t) => t.kind === track.kind
      );
      sameKindTracks.forEach(this.detachTrack);

      this.currentTracks.push(track);
      this.playerContainer.appendChild(track.attach());
      console.group("track");
      // console.log("this.participant: ", this.participant);
      // console.log("this.playerContainer: ", this.playerContainer);
      // console.log("track: ", track);
      console.groupEnd();
      // }
      // if(audioTrack) {
      //   this.playerContainer.appendChild(audioTrack.attach());
      // }
    },

    isTrackExistOnData(track) {
      return this.currentTracks.find((t) => t.name === track.name);
    },

    detachTrack(track) {
      track.detach().forEach(function (detachedElement) {
        console.log("detachedElement: ", detachedElement);
        detachedElement.remove();
      });
    },

    enableVideo() {
      // if (this.videoTrack) {
      if (!this.playerContainer)
        this.playerContainer = document.createElement("div");

      this.playerContainer.classList.add("video-item-container", "rounded");
      // playerContainer.style.width = "640px";
      // playerContainer.style.height = "480px";
      this.$refs.videoPlace.append(this.playerContainer);
      // this.attachElements()

      // this.videoTrack.play(this.playerContainer)

      // AgoraRTC.onAutoplayFailed = () => {
      //   this.paused = true;
      //   // const btn = document.createElement("button");
      //   // btn.innerText = "Click me to resume the audio playback";
      //   // btn.onClick = () => {
      //   //   btn.remove();
      //   // };
      //   // document.body.append(btn);
      // };

      // console.log("this.$refs.videoPlace: ", this.$refs.videoPlace);
      // }

      // if (this.audioTrack) {
      //   this.audioTrack.play()
      // }
    },
  },

  mounted() {
    this.enableVideo();

    console.log("this.participant: ", this.participant);

    if (this.participant) {
      this.participant.tracks.forEach((publication) => {
        console.log("track for loop : ", publication);
        console.log(
          "track.track: publication.isSubscribed: ",
          publication.track,
          publication.isSubscribed
        );
        if (publication.isSubscribed && publication.track) {
          console.log("track.track: ", publication.track);
          this.attachTrack(publication.track);
        }
      });

      this.participant.on("trackSubscribed", (track) => {
        console.log("trackSubscribed track: ", track);
        console.log(
          "this.isTrackExistOnData(track): ",
          this.isTrackExistOnData(track)
        );
        if (!this.isTrackExistOnData(track)) {
          this.attachTrack(track);
        }
      });

      this.participant.on("trackPublished", function (track) {
        console.log("trackPublished: track: ", track);
      });

      this.participant.on("trackUnpublished", (track) => {
        console.log(
          "trackUnpublished this.currentTracks: ",
          this.currentTracks
        );
        let tr = this.currentTracks.find((t) => t.name === track.trackName);
        if (tr) {
          this.detachTrack(tr);
        }
      });

      this.participant.on("trackStarted", (track) => {
        console.log("trackStarted: track: ", track);
        console.log(
          "this.isTrackExistOnData(track): ",
          this.isTrackExistOnData(track)
        );
        if (!this.isTrackExistOnData(track)) {
          this.attachTrack(track);
        }
      });
    } else {
      if (this.videoTrack) {
        this.attachTrack(this.videoTrack);
      }
      if (this.audioTrack) {
        this.attachTrack(this.audioTrack);
      }
    }
    // this.createVideoElement();
  },

  // updated() {
  // this.enableVideo()

  // if (!this.videoElement) {
  //   this.createVideoElement();
  // }
  // }
};
</script>

<style lang="scss" scoped>
.remote .username {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.local .username {
  display: none;
}
</style>
