<template>
  <div>
    <v-container ref="videoPlace">
      <v-row class="mb-3" justify="center" v-if="currentSession">
        <v-col cols="6">
          <VideoProblem
            :eventId="this.videoRoomId || ''"
            :notifyUserId="myTherapistId || currentSession.client"
          />
        </v-col>
      </v-row>

      <div v-if="!showRemoteVideo">
        <!--        <PreVideoCall leave-button @onLeave="leaveRoom" />-->

        <div class="d-flex">
          <v-btn
            v-if="isTherapist"
            color="danger"
            outlined
            class="client-not-joined mx-auto mt-10"
            :disabled="!showClientNotJoined"
            @click="clientNotJoinedAction"
          >
            Client Didn't Join
          </v-btn>
        </div>
      </div>
      <!--      class="d-flex flex-wrap video-stream-container both-inside mx-auto"-->
      <div
        v-else-if="zoomReady"
        :class="fullScreen ? 'full-screen' : 'not-full-screen'"
        ref="videoBase"
      >

        <div class="position-relative">

          <!--  <div-->
          <!--      v-for="(user, index) in zoomAllUsers"-->
          <!--      :key="index"-->
          <!--  >-->
          <!--    <pre>-->
          <!--      {{user}}-->
          <!--    </pre>-->

          <!--    {{user.id === currentUserInfo.id}}-->

          <!--  </div>-->
          <VideoCallElementZoom
            v-for="user in zoomAllUsers"
            :key="user.userId"
            :isLocal="user.userId === currentUserInfo.userId"
            :user="user"
            @onLeave="leaveRoom"
          />

          <!--        <VideoCallElementZoom-->
          <!--            :isLocal="true"-->
          <!--            userId="123"-->
          <!--        />-->
          <div class="video-button-fullscreen mt-3 mt-md-0 ms-auto ms-md-0">
            <v-btn
              v-if="!fullScreen"
              rounded
              color="rgba(0,0,0,.2)"
              class="has-backdrop"
              @click="setFullScreen"
            >
              <template>
                <v-icon color="white" centered>mdi-fullscreen</v-icon>
              </template>
            </v-btn>
            <v-btn
              v-else
              color="transparent"
              class="has-backdrop"
              @click="exitFullScreen"
            >
              <template>
                <v-icon color="white" centered>mdi-fullscreen-exit</v-icon>
              </template>
            </v-btn>
          </div>
          <div class="timer-backdrop">
            <p class="timer z-index-1" :class="{ 'danger--text': timerTimeout }">
              {{ timerTime | prettify }}
            </p>
          </div>


        </div>
        <!--        <video-item-->
        <!--          ref="localVideo"-->
        <!--          class="local rounded"-->
        <!--          :user="me.email"-->
        <!--          v-if="localVideoTrack"-->
        <!--          :videoTrack="localVideoTrack"-->
        <!--        />-->


        <!--        <template v-for="userData in usersInRoom">-->
        <!--          <video-item-->
        <!--            class="remote rounded"-->
        <!--            :key="userData[1].sid"-->
        <!--            v-if="userData[1].videoTracks.size"-->
        <!--            :participant="userData[1]"-->
        <!--          />-->
        <!--        </template>-->

        <!--        <template v-if="localVideoTrack">-->


<!--                  <video-call-buttons leave-button @onLeave="leaveRoom" />-->


<!--                </template>-->
      </div>

      <v-dialog v-model="sessionDoneDialog" width="500" persistent>
        <v-card class="rounded-lg">
          <v-card-text class="pa-8">
            <h4 class="title text-center mb-5 font-weight-bold">
              Is this session completed successfully and therefore you would
              like us to charge the client 1 live session credit?
            </h4>
          </v-card-text>
          <v-card-actions class="pb-6">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mx-2 px-5"
              large
              @click="sessionDone"
            >
              Yes
            </v-btn>

            <v-btn
              color="primary"
              text
              class="mx-2 px-5"
              large
              @click="sessionNotDone"
            >
              No
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import VideoProblem from "@/components/Video/VideoProblem";

import UserAvatar from "@/components/UserAvatar";
import UserMixin from "@/mixins/UserMixin";
import VideoItem from "@/components/Video/VideoItem";


import VideoCallButtons from "@/components/Video/VideoCallButtons";
import PreVideoCall from "@/components/Video/PreVideoCall";

import moment from "moment";
import { mapActions } from "vuex";
import VideoCallElementZoom from "@/components/Video/zoom/VideoCallElementZoom";
import ZoomMixin from "@/mixins/ZoomMixin";
// import TwilioMixin from "@/mixins/TwilioMixin";

export default {
  name: "VideoCall",
  mixins: [
    UserMixin,
    ZoomMixin
    // AgoraMixin,
    // TwilioMixin,
  ],
  components: {
    VideoCallElementZoom,

    VideoItem,
    UserAvatar,
    VideoCallButtons,
    PreVideoCall,
    VideoProblem
  },
  data: () => {
    return {
      zoomReady: false,
      currentUserInfo: null,

      currentSession: null,
      fullScreen: false,
      startDate: new Date(),
      timerTime: "00:00",
      timerTimeout: false,
      videoRoomId: null,
      showClientNotJoined: false,
      userHasJoined: false,

      sessionDoneDialog: false,
      leaveRoomPath: ""
    };
  },

  computed: {
    // usersInRoom() {
    //   console.log("usersInRoom: ", this.TwilioVideoStream.participants);
    //   return this.TwilioVideoStream.participants;
    //   // return this?.TwilioVideoStream?.room?.participants || null
    //   // return this?.AgoraVideoStream?.client?.remoteUsers || [];
    // },

    showRemoteVideo() {
      return true;
      // return this.hasRemoteUser
      // if (!this.hasRemoteUser) {
      //   return false;
      // }
      // let users = Array.from(this.usersInRoom, ([name, value]) => value);
      // return users?.[0]?.tracks?.size;
    }
  },

  async beforeRouteLeave(to, from, next) {
    this.leaveRoomPath = to.path;
    // await this.$AgoraVideoStream.leaveChanel().catch(console.error);
    await this.doneSessionAction();
    await this.TwilioVideoStream.leaveChanel();

    // window.location.href = to.path;

    // next()
    // called when the route that renders this component is about to
    // be navigated away from.
    // As with `beforeRouteUpdate`, it has access to `this` component instance.
  },

  methods: {
    ...mapActions("calendar", [
      "notJoined",
      "getEventById",
      "doneEvent",
      // "getAgoraToken",
      "getTwilioToken"
    ]),

    ...mapActions("user", [
      "refreshAccessToken"
    ]),

    async doneSessionAction() {
      if (this.isTherapist && this.userHasJoined) {
        this.sessionDoneDialog = true;
        // let result = confirm('Session has done?');
        // if (result) {
        //   await this.doneEvent({id: this.videoRoomId})
        // }
        // window.onbeforeunload = null;
      } else {
        await this.sessionNotDone();
      }
    },

    async sessionDone() {
      try {
        await this.doneEvent({ id: this.videoRoomId });
        await this.sessionNotDone();
      } catch (e) {
        this.addNotification({
          message: e.response.data.message,
          icon: "mdi-alert",
          timeout: 6000
        });
      }
    },

    async sessionNotDone() {
      window.onbeforeunload = null;
      window.location.href = this.leaveRoomPath;
    },

    leaveRoom() {
      if (
        this.isClient &&
        (this.me?.metadata?.numberLiveSessionsHave > 0 ||
          this.me?.metadata?.numberCorporateLiveSessionsHave > 0 ||
          this.me?.metadata?.numberFreeLiveSessionsHave > 0)
      ) {
        this.$router.replace({ name: "NextSessionSuggestion" });
      } else {
        this.$router.replace({ name: "Calendar" });
      }
    },

    setFullScreen() {
      this.$refs.videoBase.requestFullscreen().then(() => {
        this.fullScreen = true;
      });
    },

    exitFullScreen() {
      window.document.exitFullscreen().then(() => {
        this.fullScreen = false;
      });
    },

    async clientNotJoinedAction() {
      let result = confirm("Confirm Action");

      if (result && this.showClientNotJoined) {
        try {
          await this.notJoined({ id: this.videoRoomId });
          await this.$router.replace({ name: "Calendar" });
        } catch (e) {
          this.addNotification({
            message: e.response.data.message,
            icon: "mdi-alert",
            timeout: 6000
          });
        }
      }
    }
  },

  filters: {
    prettify: function(value) {
      let data = value.split(":");
      let minutes = data[0];
      let seconds = data[1];
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return minutes + ":" + seconds;
    }
  },

  async mounted() {
    this.refreshAccessToken();

    window.vidCall = this;

    this.videoRoomId = this.$route.params.id;

    this.currentSession = await this.getEventById({
      id: this.videoRoomId
    }).catch((err) => {
      this.addNotification({
        message: err?.response?.data?.message,
        icon: "mdi-check-outline",
        timeout: 3000
      });

      setTimeout(() => {
        location.href = "/calendar";
      }, 4000);
    });

    if (!this.ZoomStream) {
      await this.getAccessToken(this.videoRoomId);
      await this.ZoomVideoStream.joinRoom(this.videoRoomId, this.getUserName(this.me));
    }

    this.zoomReady = true;

    this.currentUserInfo = this.ZoomVideoStream.client.getCurrentUserInfo();


    if (this.isTherapist) {
      // if (this.hasRemoteUser) {
      //   this.userHasJoined = true;
      //
      //   window.onbeforeunload = () => {
      //     return "Session has done?";
      //   };
      // }

      // this.TwilioVideoStream.room.on("participantConnected", (participant) => {
      //   this.userHasJoined = true;
      //
      //   window.onbeforeunload = () => {
      //     return "Session has done?";
      //   };
      // });

    }

    if (this.isTherapist) {
      let interval = setInterval(() => {
        if (
          moment(this.currentSession.eventStartDate)
            .add(15, "minutes")
            .isBefore(moment())
        ) {
          this.showClientNotJoined = true;
        }

        if (this.hasRemoteUser) {
          this.showClientNotJoined = false;
          clearInterval(interval);
        }
      }, 5000);
    }

    setInterval(() => {
      let diff = Math.abs((new Date() - this.startDate) / 1000);
      let limitMinute = this.currentSession.status === "bookedFree" ? 20 : 50;

      if (diff > limitMinute * 60) {
        this.timerTimeout = true;
      }

      this.timerTime = Math.floor(diff / 60) + ":" + Math.floor(diff % 60);
    }, 1000);
  },

  async beforeDestroy() {

  }
};
</script>
