import {mapActions} from "vuex";

export default {
  data: () => {
    return {
      ZoomVideoStream: null,
      // ZoomClient: null,
      // ZoomStream: null,

      // microphones: [],
      // videoEnabled: false,
      // audioEnabled: false,
      // usersInRoom: null,
    };
  },

  computed: {
    // localAudioTrack() {
    //   return this.TwilioVideoStream.localAudioTrack;
    // },

    ZoomClient() {
      return this.$ZoomVideoStream.client
    },

    ZoomStream() {
      return this.$ZoomVideoStream.stream
    },

    localVideoTrack() {
      return this.ZoomVideoStream.localVideoTrack;
    },

    localAudioTrack() {
      return this.ZoomVideoStream.localAudioTrack;
    },

    videoDevices() {
      return this.ZoomVideoStream.videoDevices;
    },

    audioDevices() {
      return this.ZoomVideoStream.audioDevices;
    },

    zoomAccessToken() {
      return this.ZoomVideoStream.accessToken;
    },

    zoomAllUsers() {
      return this.ZoomVideoStream.allUsers;
    },

    // hasRemoteUser() {
    //   return this.usersInRoom && this.usersInRoom.size;
    //   // return this.AgoraVideoStream.remoteUserPublished;
    // },

    // videoEnabled() {
    //     return this.TwilioVideoStream.videoEnabled;
    // },

    // audioEnabled() {
    //     return this.TwilioVideoStream.audioEnabled;
    // },
  },

  watch: {
    // "TwilioVideoStream.videoEnabled"(val) {
    //   this.videoEnabled = val;
    // },
    //
    // "TwilioVideoStream.audioEnabled"(val) {
    //   this.audioEnabled = val;
    // },
    //
    // "TwilioVideoStream._updated"(val) {
    //   console.log("TwilioVideoStream._updated: ", val);
    //   this.usersInRoom = null;
    //   this.$nextTick(() => {
    //     this.usersInRoom = this.TwilioVideoStream.participants;
    //   });
    // },
  },

  methods: {

    ...mapActions("calendar", [
      "getZoomToken",
    ]),

    async getAccessToken(id) {
      this.ZoomVideoStream.accessToken = await this.getZoomToken({id});
    }

    // updateCameraMicEnabledState() {
    //   this.videoEnabled = this.TwilioVideoStream.videoEnabled;
    //   this.audioEnabled = this.TwilioVideoStream.audioEnabled;
    // },
    //
    // async getVideoDevices() {
    //     await this.ZoomVideoStream.getVideoDevices()
    // },
    //
    // async getMicrophones() {
    //   this.microphones = await this.TwilioVideoStream.getMicrophones();
    // },
    //
    // setCamera(deviceId) {
    //   this.TwilioVideoStream.setCamera(deviceId);
    // },
    //
    // async setMicrophone(deviceId) {
    //   await this.TwilioVideoStream.setMicrophone(deviceId);
    // },
    //
    // toggleMyVideoActive() {
    //   this.TwilioVideoStream.toggleMyVideoActive();
    //   this.updateCameraMicEnabledState();
    // },
    //
    // toggleMyAudioActive() {
    //   this.TwilioVideoStream.toggleMyAudioActive();
    //   this.updateCameraMicEnabledState();
    // },
    //
    // removeVideoStream() {
    //   this.TwilioVideoStream.removeVideoStream();
    // },
    //
    // async getOrCreateTracks() {
    //   await this.TwilioVideoStream.getOrCreateTracks();
    //   this.updateCameraMicEnabledState();
    // },
  },

  created() {
    this.ZoomVideoStream = this.$ZoomVideoStream;


    // this.getCameras();
    // this.getMicrophones();
  },
};
