<template>
  <v-row v-if="isTherapist && currentEvent">
    <v-col cols="12" md="12" class="px-5 mb-3">
      <p class="mb-4 fs-20px mt-15 text-center">
        <v-icon color="warning" left>mdi-list-status</v-icon>
        Confirm the Status of the session
      </p>
    </v-col>

    <v-col
      cols="12"
      class="px-7 mb-2 py-0"
      v-for="(item, i) in actions"
      :key="i"
    >
      <v-btn
        block
        color="primary"
        :outlined="!(reasonTextArea && item.name === 'Other')"
        class="py-5 mb-5"
        @click="submitAction(item)"
        ><v-icon :color="item.iconColor" left>{{ item.icon }}</v-icon>
        {{ item.name }}</v-btn
      >
    </v-col>
    <v-col cols="12" class="px-7">
      <v-form ref="form" v-model="valid" lazy-validation v-if="reasonTextArea">
        <v-textarea
          counter
          outlined
          v-model="otherReason"
          :rules="textAreaRules"
        ></v-textarea>
        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-form>
    </v-col>

    <v-dialog v-model="dialog" width="500">
      <v-card class="pa-8">
        <v-btn
          @click="dialog = false"
          elevation="2"
          icon
          class="close-icon pa-0"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-text class="text-center">
          Are you sure you want to confirm this reason?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mx-2" text @click="dialog = false">
            No
          </v-btn>
          <v-btn color="primary" class="mx-2" outlined @click="submitReason">
            Yes
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";
import { mapActions } from "vuex";

export default {
  name: "CalendarEventsVideoActions",
  mixins: [UserMixin],
  props: {
    currentEvent: {
      required: true,
    },
  },

  data: () => {
    return {
      dialog: false,
      valid: true,
      reasonAction: null,
      otherReason: "",
      reasonTextArea: false,
      textAreaRules: [
        (v) => !!v || "Reason is Required",
        (v) => (v && v.length >= 10) || "Reason must be minimum 10 characters",
      ],
    };
  },
  computed: {
    actions() {
      return [
        {
          name: "Completed Successfully",
          value: "completedSuccessfully",
          icon: "mdi-check",
          iconColor: "success",
          cb: this.doneEventAction,
        },
        {
          name: "Unsuccessful - Client did not join",
          value: "clientDidNotJoin",
          icon: "mdi-close",
          iconColor: "error",
          cb: this.clientNotJoinedEventAction,
        },
        {
          name: "Unsuccessful - Technical Issues",
          value: "technicalIssues",
          icon: "mdi-close",
          iconColor: "error",
          cb: this.cancelEventAction,
        },
        {
          name: "Other",
          value: this.otherReason,
          cb: this.cancelEventAction,
        },
      ];
    },
  },

  methods: {
    ...mapActions("calendar", ["doneEvent", "cancelEvent", "notJoined"]),

    submitAction(currentItem) {
      if (currentItem && currentItem.name !== "Other") {
        this.dialog = true;
        this.reasonAction = currentItem.cb;
      } else {
        this.reasonTextArea = !this.reasonTextArea;
        this.reasonAction = currentItem.cb;
      }
    },

    async doneEventAction() {
      try {
        await this.doneEvent({ id: this.currentEvent._id });
        await this.$router.replace({ name: "Calendar" });
      } catch (e) {
        this.addNotification({
          message: e.response.data.message,
          icon: "mdi-alert",
          timeout: 6000,
        });
      }
    },

    async clientNotJoinedEventAction() {
      try {
        await this.notJoined({ id: this.currentEvent._id });
        await this.$router.replace({ name: "Calendar" });
      } catch (e) {
        this.addNotification({
          message: e.response.data.message,
          icon: "mdi-alert",
          timeout: 6000,
        });
      }
    },

    async cancelEventAction() {
      try {
        await this.cancelEvent({
          id: this.currentEvent._id,
          data: {
            otherText: this.otherReason || "",
          },
        });
        await this.$router.replace({ name: "Calendar" });
      } catch (e) {
        this.addNotification({
          message: e.response.data.message,
          icon: "mdi-alert",
          timeout: 6000,
        });
      }
    },

    async submitReason() {
      this.dialog = false;
      if (typeof this.reasonAction === "function") {
        await this.reasonAction();
      }
    },

    async validate() {
      this.$refs.form.validate();
      if (this.valid) {
        this.dialog = true;
        this.reasonAction = this.cancelEventAction;
        this.otherReason = "";
      }
    },
  },
};
</script>
