<template>
  <div v-if="showEvent">
    <v-btn
      v-if="!opens"
      small
      block
      :outlined="event.status === 'available'"
      :color="color"
      :dark="event.status === 'done'"
      class="mb-3 py-5"
      elevation="0"
      @click="openEvent"
    >
      {{ event.eventStartDate | moment(momentFormatter) }} -
      {{ event.eventEndDate | moment(momentFormatter) }}
    </v-btn>

    <div v-if="opens">
      <v-row class="mt-0 mb-3">
        <v-col cols="12" class="py-0">
          <v-btn
            height="42"
            :color="color"
            class="w-100 font-size-12"
            :outlined="event.status === 'available'"
            @click="opens = false"
          >
            {{ event.eventStartDate | moment(momentFormatter) }} -
            {{ event.eventEndDate | moment(momentFormatter) }}
          </v-btn>
        </v-col>

        <v-col cols="12" md="12" class="py-0 d-flex px-md-4 mt-3">
          <v-btn
            v-if="event.status === 'available' && haveAFreeSession"
            color="accent"
            outlined
            height="auto"
            class="flex-grow-1 font-weight-bold mx-2 pa-2 font-size-12 mb-3"
            @click="confirm(event, true)"
          >
            <v-icon x-small left class="me-1 success--text">mdi-check</v-icon>
            {{ $t("Book free 20min") }}
          </v-btn>

          <v-btn
            v-if="event.status === 'available'"
            :disabled="!canBookEvent"
            color="accent"
            outlined
            height="auto"
            class="flex-grow-1 font-weight-bold mx-2 pa-2 font-size-12 mb-3"
            @click="confirm(event)"
          >
            <v-icon x-small left class="me-1 success--text">mdi-check</v-icon>
            {{ $t("Book 50min") }}
          </v-btn>

          <v-btn
            v-else-if="['booked', 'bookedFree'].includes(event.status)"
            color="danger"
            outlined
            height="auto"
            class="flex-grow-1 font-weight-bold mx-2 pa-2 font-size-12 mb-3"
            @click="decline(event)"
          >
            <v-icon x-small left class="me-1 danger--text"
              >mdi-close-circle</v-icon
            >
            {{ $t("Cancel") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import UserMixin from "@/mixins/UserMixin";
import TherapistMixin from "@/mixins/TherapistMixin";

export default {
  name: "ClientEvent",
  props: {
    event: {
      required: true,
    },
    showFullDate: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [UserMixin, TherapistMixin],

  data: () => {
    return {
      opens: false,
    };
  },

  computed: {
    color() {
      if (this.event.status === "available") {
        return "primary";
      } else if (this.event.status === "booked") {
        return "secondary";
      } else if (this.event.status === "bookedFree") {
        return "secondary";
      } else if (this.event.status === "done") {
        return "indigo";
      } else if (this.event.status === "unsuccessful") {
        return "error";
      }

      return "";
    },

    haveAFreeSession() {
      return this.me?.metadata?.numberFreeLiveSessionsHave > 0;
    },

    canBookEvent() {
      return (
        this.me?.metadata?.numberLiveSessionsHave > 0 ||
        this.me?.metadata?.numberCorporateLiveSessionsHave > 0
      );
    },

    showEvent() {
      return (
        (["booked", "bookedFree"].includes(this.event.status) &&
          moment(this.event.eventEndDate).isAfter(moment())) ||
        (this.event.status !== "declined" &&
          moment(this.event.eventStartDate)
            .subtract(3, "hours")
            .isAfter(moment()))
      );
    },

    momentFormatter() {
      return this.showFullDate ? "llll" : "LT";
    },
  },

  methods: {
    confirm(event, free) {
      if (this.canBookEvent || this.haveAFreeSession) {
        if (free) {
          this.$emit("confirmFree", event);
        } else {
          this.$emit("confirm", event);
        }
        this.opens = false;
      }
    },

    decline(event) {
      this.$emit("decline", event);
      this.opens = false;
    },

    openEvent() {
      if (["available", "booked", "bookedFree"].includes(this.event.status)) {
        this.opens = true;
      }
    },
  },
};
</script>

<style scoped></style>
