<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="secondary" large block v-bind="attrs" v-on="on">
          <v-icon left small>mdi-cog</v-icon>
          {{ $t("Test Video / Audio") }}
        </v-btn>
      </template>

      <v-card>
        <v-btn
          @click="dialog = false"
          elevation="2"
          icon
          class="close-icon pa-0"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <TestVideoCallZoom v-if="dialog" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ZoomMixin from "@/mixins/ZoomMixin";
import TestVideoCallZoom from "@/components/Video/zoom/TestVideoCallZoom.vue";

export default {
  components: {
    TestVideoCallZoom
  },
  mixins: [
    ZoomMixin
  ],

  data() {
    return {
      dialog: false,
    };
  },

  beforeDestroy() {
    // this.removeVideoStream();
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog.v-dialog--active {
  box-shadow: none !important;
}
::v-deep .v-card {
  width: 500px;
  box-shadow: none !important;
  background-color: transparent !important;
  .video-stream-container {
    margin: unset !important;
  }
  video {
    width: 500px;
  }
}
</style>