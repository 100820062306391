<template>
  <v-alert
    color="secondary"
    class="mb-0 not-support bottom"
    rounded="0"
    dismissible
  >
    <p class="mb-0 white--text text-center">
      {{
        $t(
          "To ensure a quality experience when conducting live (video/audio) sessions, please use your private laptop and a recently updated version of"
        )
      }}
      <b>{{ $t("Google Chrome browser.") }}</b>
    </p>
  </v-alert>
</template>

<script>
export default {
  name: "BrowserAlert",
};
</script>
