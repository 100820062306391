<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="700">
      <v-card class="py-8 px-15">
        <v-btn @click="close" elevation="2" icon class="close-icon pa-0">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-select
          :items="microphoneItems"
          item-text="label"
          item-value="label"
          return-object
          :value="currentMicrophone"
          @change="changeMicrophone"
          :label="$t('Microphone')"
        >
          <template v-slot:append-outer>
            <div class="ms-7 d-flex">
              <template v-if="audioEnabled">
                <v-icon centered>mdi-microphone</v-icon>
              </template>
              <template v-else>
                <v-icon color="danger" centered>mdi-microphone-off</v-icon>
              </template>

              <div
                class="volume-level-container primary ms-2"
                :class="{ 'volume-level-animated': dialog }"
              >
                <div class="volume-level-dot" ref="volumeLevelDotRef1"></div>
                <div class="volume-level-dot" ref="volumeLevelDotRef2"></div>
                <div class="volume-level-dot" ref="volumeLevelDotRef3"></div>
              </div>
            </div>
          </template>
        </v-select>
        <v-select
          :items="cameraItems"
          item-text="label"
          item-value="label"
          return-object
          :value="currentCamera"
          @change="changeCamera"
          :label="$t('Camera')"
        >
          <template v-slot:append-outer>
            <div class="ms-14 d-flex me-auto">
              <v-icon>mdi-video</v-icon>
            </div>
          </template>
        </v-select>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import AgoraMixin from "@/mixins/AgoraMixin";

import TwilioMixin from "@/mixins/TwilioMixin";

export default {
  name: "SettingsDialog",

  mixins: [TwilioMixin],

  data: () => {
    return {
      dialog: false,
      volumeAnimation: null,
    };
  },

  watch: {
    dialog(val) {
      if (val) {
        this.setVolumeWave();
      } else {
        cancelAnimationFrame(this.volumeAnimation);
      }
    },
  },

  computed: {
    currentCamera() {
      return this.localVideoTrack?.mediaStreamTrack?.label;
    },

    currentMicrophone() {
      return this.localAudioTrack?.mediaStreamTrack?.label;
    },

    cameraItems() {
      return this.cameras;
    },

    microphoneItems() {
      return this.microphones;
    },
  },

  methods: {
    getActiveMediaDevice(e) {
      console.log("e: ", e);
      return false;
    },

    async changeMicrophone(device) {
      await this.setMicrophone(device.deviceId);

      cancelAnimationFrame(this.volumeAnimation);
      await this.setVolumeWave();
    },

    changeCamera(device) {
      this.setCamera(device.deviceId);
    },

    async setVolumeWave() {
      let audioContext = new AudioContext();
      const stream = await this.localAudioTrack.attach().srcObject;
      let mediaStreamSource = audioContext.createMediaStreamSource(stream);

      const analyserNode = audioContext.createAnalyser();

      mediaStreamSource.connect(analyserNode);

      const pcmData = new Float32Array(analyserNode.fftSize);
      const onFrame = () => {
        analyserNode.getFloatTimeDomainData(pcmData);
        let sumSquares = 0.0;
        for (const amplitude of pcmData) {
          sumSquares += amplitude * amplitude;
        }
        let level = Math.sqrt(sumSquares / pcmData.length) * 100;

        if (this.$refs.volumeLevelDotRef1) {
          this.$refs.volumeLevelDotRef1.style.height = level / 2 + "em";
          this.$refs.volumeLevelDotRef2.style.height = level + "em";
          this.$refs.volumeLevelDotRef3.style.height = level / 2 + "em";
        }

        this.volumeAnimation = requestAnimationFrame(onFrame);
      };
      this.volumeAnimation = requestAnimationFrame(onFrame);

      // this.volumeAnimation = requestAnimationFrame(this.setVolumeWave);
      // let level = this.localAudioTrack?.getVolumeLevel() * 8 || 0;
      // if(this.$refs.volumeLevelDotRef1) {
      //   this.$refs.volumeLevelDotRef1.style.height = level / 2 + 'em';
      //   this.$refs.volumeLevelDotRef2.style.height = level + 'em';
      //   this.$refs.volumeLevelDotRef3.style.height = level / 2 + 'em';
      // }
    },

    close() {
      this.dialog = false;
    },
    open() {
      this.dialog = true;
    },
  },

  beforeDestroy() {
    cancelAnimationFrame(this.volumeAnimation);
  },
};
</script>

<style scoped lang="scss">
.volume-level-container {
  align-items: center;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;

  height: 1.625em;
  width: 1.625em;
}

.volume-level-animated .volume-level-dot {
  animation: volumeLevelJiggle 600ms steps(9, jump-none) 0s infinite;
}

.volume-level-dot {
  background-color: currentColor;
  border-radius: 0.125em/0.125em;
  height: 0.25em;
  min-height: 0.25em;
  max-height: 0.95em;
  width: 0.25em;
  will-change: height, transform;
  z-index: 1;
  margin: 0 1px;
}

@keyframes volumeLevelJiggle {
  0% {
    transform: scaleY(0.8);
  }

  50% {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0.8);
  }
}
</style>
