<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="700">
      <v-card class="py-8 px-15">
        <v-btn @click="close" elevation="2" icon class="close-icon pa-0">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-select
            :items="audioDevices"
            item-text="label"
            item-value="deviceId"
            return-object
            :value="activeMicrophone"
            @change="changeMicrophone"
            :label="$t('Microphone')"
        >
          <template v-slot:append-outer>
            <div class="ms-7 d-flex">
              <template v-if="microphoneEnabled">
                <v-icon centered>mdi-microphone</v-icon>
              </template>
              <template v-else>
                <v-icon color="danger" centered>mdi-microphone-off</v-icon>
              </template>

              <div
                  class="volume-level-container primary ms-2"
                  :class="{ 'volume-level-animated': dialog }"
              >
                <div class="volume-level-dot" ref="volumeLevelDotRef1"></div>
                <div class="volume-level-dot" ref="volumeLevelDotRef2"></div>
                <div class="volume-level-dot" ref="volumeLevelDotRef3"></div>
              </div>
            </div>
          </template>
        </v-select>

        <v-select
            :items="videoDevices"
            item-text="label"
            item-value="deviceId"
            :value="activeCamera"
            @change="changeCamera"
            :label="$t('Camera')"
        >
          <template v-slot:append-outer>
            <div class="ms-14 d-flex me-auto">
              <v-icon>mdi-video</v-icon>
            </div>
          </template>
        </v-select>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import ZoomMixin from "@/mixins/ZoomMixin";

export default {
  name: "SettingsDialogZoom",

  mixins: [ZoomMixin],

  props: [
    'switchCamera',
    'videoTrack',
    'activeCamera',

    'audioTrack',
    'microphoneEnabled',
    'activeMicrophone',
    'switchMicrophone',
  ],

  data: () => {
    return {
      dialog: false,
      volumeAnimation: null,
      microphoneTester: null,
    };
  },

  watch: {
    dialog(val) {
      if (val) {
        this.ZoomVideoStream.getVideoDevices();
        this.ZoomVideoStream.getAudioDevices();
        if(!this.microphoneTester) {
          this.setVolumeWave();
        }
      } else {
        this.microphoneTester?.stop();
        this.microphoneTester = null;
        // cancelAnimationFrame(this.volumeAnimation);
      }
    },
  },

  methods: {
    changeCamera(device) {
      this.switchCamera(device.deviceId);
    },

    async changeMicrophone(device) {
      this.switchMicrophone(device.deviceId)

      // cancelAnimationFrame(this.volumeAnimation);
      this.microphoneTester?.stop();
      await this.setVolumeWave();
    },


    async setVolumeWave() {
      if(this.audioTrack) {
        this.microphoneTester = this.audioTrack.testMicrophone({
          microphoneId: this.activeMicrophone,
          // speakerId: activeSpeaker,
          onAnalyseFrequency: (level) => {
            if (this.$refs.volumeLevelDotRef1) {
              this.$refs.volumeLevelDotRef1.style.height = level / 14 + "em";
              this.$refs.volumeLevelDotRef2.style.height = level / 12 + "em";
              this.$refs.volumeLevelDotRef3.style.height = level / 14 + "em";
            }
            // inputLevelElm.value = v;
          },
          recordAndPlay: true,
          onStartRecording: () => {
            // target.textContent = "Recording";
            // target.dataset["start"] = "2";
          },
          onStartPlayRecording: () => {
            // target.textContent = "Playing";
            // target.dataset["start"] = "3";
          },
          onStopPlayRecording: () => {
            // target.textContent = "Stop test";
            // target.dataset["start"] = "1";
          },
        });

      }

      // const onFrame = () => {


        // this.volumeAnimation = requestAnimationFrame(onFrame);
      // };
      // this.volumeAnimation = requestAnimationFrame(onFrame);
    },

    close() {
      this.dialog = false;
    },
    open() {
      this.dialog = true;
    },
  },

  beforeDestroy() {
    this.microphoneTester?.stop();
    this.microphoneTester = null;
    // cancelAnimationFrame(this.volumeAnimation);
  },

  async mounted() {
    await this.ZoomVideoStream.getVideoDevices();
    await this.ZoomVideoStream.getAudioDevices();
  }
};
</script>

<style scoped lang="scss">
.volume-level-container {
  align-items: center;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;

  height: 1.625em;
  width: 1.625em;
}

.volume-level-animated .volume-level-dot {
  animation: volumeLevelJiggle 600ms steps(9, jump-none) 0s infinite;
}

.volume-level-dot {
  background-color: currentColor;
  border-radius: 0.125em/0.125em;
  height: 0.25em;
  min-height: 0.25em;
  max-height: 0.95em;
  width: 0.25em;
  will-change: height, transform;
  z-index: 1;
  margin: 0 1px;
}

@keyframes volumeLevelJiggle {
  0% {
    transform: scaleY(0.8);
  }

  50% {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0.8);
  }
}
</style>
