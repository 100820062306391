export default {
  data: () => {
    return {
      TwilioVideoStream: null,
      cameras: [],
      microphones: [],
      videoEnabled: false,
      audioEnabled: false,
      usersInRoom: null,
    };
  },

  computed: {
    localAudioTrack() {
      return this.TwilioVideoStream.localAudioTrack;
    },

    localVideoTrack() {
      return this.TwilioVideoStream.localVideoTrack;
    },

    hasRemoteUser() {
      return this.usersInRoom && this.usersInRoom.size;
      // return this.AgoraVideoStream.remoteUserPublished;
    },

    // videoEnabled() {
    //     return this.TwilioVideoStream.videoEnabled;
    // },
    //
    // audioEnabled() {
    //     return this.TwilioVideoStream.audioEnabled;
    // },
  },

  watch: {
    "TwilioVideoStream.videoEnabled"(val) {
      this.videoEnabled = val;
    },

    "TwilioVideoStream.audioEnabled"(val) {
      this.audioEnabled = val;
    },

    "TwilioVideoStream._updated"(val) {
      console.log("TwilioVideoStream._updated: ", val);
      this.usersInRoom = null;
      this.$nextTick(() => {
        this.usersInRoom = this.TwilioVideoStream.participants;
      });
    },
  },

  methods: {
    updateCameraMicEnabledState() {
      this.videoEnabled = this.TwilioVideoStream.videoEnabled;
      this.audioEnabled = this.TwilioVideoStream.audioEnabled;
    },

    async getCameras() {
      this.cameras = await this.TwilioVideoStream.getCameras();
    },

    async getMicrophones() {
      this.microphones = await this.TwilioVideoStream.getMicrophones();
    },

    setCamera(deviceId) {
      this.TwilioVideoStream.setCamera(deviceId);
    },

    async setMicrophone(deviceId) {
      await this.TwilioVideoStream.setMicrophone(deviceId);
    },

    toggleMyVideoActive() {
      this.TwilioVideoStream.toggleMyVideoActive();
      this.updateCameraMicEnabledState();
    },

    toggleMyAudioActive() {
      this.TwilioVideoStream.toggleMyAudioActive();
      this.updateCameraMicEnabledState();
    },

    removeVideoStream() {
      this.TwilioVideoStream.removeVideoStream();
    },

    async getOrCreateTracks() {
      await this.TwilioVideoStream.getOrCreateTracks();
      this.updateCameraMicEnabledState();
    },
  },

  created() {
    this.TwilioVideoStream = this.$TwilioVideoStream;
    this.getCameras();
    this.getMicrophones();
  },
};
