<template>
  <div
      class="d-flex flex-wrap video-stream-container mx-auto position-relative w-100"
  >


    <div>
      <video id="preview-camera-video" class="rounded-15px" playsinline></video>
    </div>

    <video-call-buttons-zoom
        :leaveButton="leaveButton"

        :videoEnabled="videoEnabled"
        :switchCamera="switchCamera"
        :videoTrack="localVideoTrack"
        :activeCamera="activeCamera"
        @toggleVideoActive="onToggleVideoActive"

        :microphoneEnabled="microphoneEnabled"
        :activeMicrophone="activeMicrophone"
        :switchMicrophone="switchMicrophone"
        :audioTrack="localAudioTrack"
        @toggleAudioActive="onToggleAudioActive"
        class="position-absolute bottom-center bottom-20px w-100 text-center"
    />
  </div>
</template>

<script>
/* eslint-disable */

import UserMixin from "@/mixins/UserMixin";
import VideoCallButtonsZoom from "@/components/Video/zoom/VideoCallButtonsZoom.vue";
import ZoomMixin from "@/mixins/ZoomMixin";

export default {
  name: "TestVideoCallZoom",

  mixins: [
    UserMixin,
    ZoomMixin
  ],

  props: {
    leaveButton: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    VideoCallButtonsZoom,
  },

  data() {
    return {
      // localVideoTrack: null,
    };
  },

  computed: {
    videoEnabled() {
      return !!this.localVideoTrack?.isVideoStarted
    },

    activeCamera() {
      return this.localVideoTrack?.deviceId
    },

    microphoneEnabled() {
      return !!this.localAudioTrack?.isMicUnmuted
    },

    activeMicrophone() {
      return this.localAudioTrack?.deviceId
    }
  },

  methods: {
    async startVideo() {
      this.localVideoTrack.start(document.querySelector('#preview-camera-video'))
    },

    async switchCamera(cameraId) {
      if (this.localVideoTrack)
        this.localVideoTrack.stop().then(async () => {
          await this.ZoomVideoStream.setLocalVideoTrack(cameraId);

          this.startVideo()
        })
    },

    async onToggleVideoActive() {
      if (this.localVideoTrack.isVideoStarted) {
        this.localVideoTrack.stop()
      } else {
        await this.ZoomVideoStream.setLocalVideoTrack(this.activeCamera || null);
        this.startVideo()
      }
    },

    async startAudio() {
      await this.localAudioTrack.start()
      await this.localAudioTrack.unmute()
    },

    async onToggleAudioActive() {
      if (this.localAudioTrack.isMicUnmuted) {
        await this.localAudioTrack.mute()
      } else {
        this.localAudioTrack.unmute()
      }
    },

    async switchMicrophone(microphoneId) {
      await this.localAudioTrack.stop()

      await this.ZoomVideoStream.setLocalAudioTrack(microphoneId);

      await this.startAudio()
    }

  },

  beforeDestroy() {
    this.localVideoTrack.stop()
    this.localAudioTrack.stop()
  },


  async mounted() {
    await this.ZoomVideoStream.getVideoDevices();
    await this.ZoomVideoStream.getAudioDevices();
    await this.ZoomVideoStream.setLocalVideoTrack();
    await this.ZoomVideoStream.setLocalAudioTrack();

    await this.startVideo()
    await this.startAudio()
  },
};
</script>

<style scoped>
video {
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
}
.bottom-20px {
  bottom: 20px;
}
</style>
