<template>
  <v-container align-center class="d-flex flex-column pt-lg-12 mt-5 px-3">
    <v-row class="w-100">
      <v-col
        :cols="sheduleNextCall ? 12 : 12"
        :md="sheduleNextCall ? 12 : 12"
        class="d-flex flex-column align-center"
      >
        <v-icon color="green" class="display-4" x-large
          >mdi-check-circle</v-icon
        >
        <h2 class="display-1 font-weight-bold mt-7 mb-5" style="max-width: 680px;text-align: center;">
          {{ $t('Session Completed! Your commitment to growth is inspiring') }}
        </h2>
<!--        <p class="opacity-75 mb-12">Please rate the quality of the call:</p>-->
        <v-row class="mx-auto" justify="center">
<!--          <v-btn-->
<!--            color="primary"-->
<!--            class="mx-1 rounded-pill"-->
<!--            @click="$router.replace({ name: 'InteractiveJournal' })"-->
<!--            >Add notes</v-btn-->
<!--          >-->
          <v-btn
            text
            color="primary"
            class="mx-1 rounded-pill"
            @click="dialog = true"
            >{{ $t("Schedule my next session") }}
            <v-icon small right>mdi-chevron-right</v-icon></v-btn
          >
        </v-row>
      </v-col>

      <v-dialog v-model="dialog" max-width="450px" scrollable>
        <v-card
          rounded="lg"
          outlined
          class="pa-4 overflow-y-auto"
          style="max-height: calc(100vh - 100px)"
          elevation="0"
        >
          <v-card-title class="py-2">
            <h5 class="my-0 lh-26px">
              Book your next live session with
              {{
                this.me.therapist.profile.firstName +
                " " +
                this.me.therapist.profile.lastName
              }}.
            </h5>
          </v-card-title>
          <v-card-text>
            <p v-if="availableEvents.length > 0">
              Here are some suggested available times for
              {{
                this.me.therapist.profile.firstName +
                " " +
                this.me.therapist.profile.lastName
              }}
              Please click to choose one:
            </p>
            <p v-else>
              There is no available Sessions.
            </p>
            <template v-for="event in availableEvents">
              <ClientEvent
                :key="event._id + 'e'"
                showFullDate
                :event="event"
                @confirm="onBookEventAction"
                @confirmFree="onBookEventActionFree"
                @decline="declineEventAction"
              />
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
  <!-- <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar
          flat
        >
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            Today
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          type="category"
          category-show-all
          :categories="categories"
          :events="events"
          :event-color="getEventColor"
          @change="fetchEvents"
        ></v-calendar>
      </v-sheet>
    </v-col>
    {{this.me.therapistInfo}}
  </v-row>  -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ClientEvent from "@/components/Calendar/ClientEvent";
// import Calendar from '@/views/loggedIn/Layout/Calendar'
import UserMixin from "@/mixins/UserMixin";
const moment = require("moment");

export default {
  name: "NextSessionSuggestion",
  components: { ClientEvent },
  mixins: [UserMixin],

  data: () => ({
    dialog: true,
    // focus: '',
    // events: [],
    // colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    // categories: ['Tori Walker'],
    sheduleNextCall: false,
  }),
  computed: {
    ...mapGetters("calendar", ["events"]),

    eventsFromToday() {
      return this.events.filter((e) =>
        moment(e.eventStartDate).isAfter(moment())
      );
    },

    availableEvents() {
      return this.eventsFromToday
        .filter((e) => e.status === "available")
        .slice(0, 6);
    },
  },
  methods: {
    ...mapActions("calendar", [
      "getEvents",
      // "createEvent",
      // "updateEvent",
      // "removeEvent",
      "bookEvent",
      "bookFreeEvent",
      // "acceptEvent",
      "declineEvent",
      // "doneEvent",
    ]),

    declineEventAction(event) {
      // this.$emit('declineEventAction', event)
      console.log("declineEvent", event);
    },

    // bookEventActionFree(event) {
    //   this.$emit('bookEventActionFree', event)
    // }
    // ,
    // bookEventAction(event) {
    //   this.$emit('bookEventAction', event)
    // },
    async onBookEventAction(event) {
      await this.bookEventAction(event);
    },

    async onBookEventActionFree(event) {
      await this.bookFreeEventAction(event);
    },

    async bookFreeEventAction(event) {
      this.loading = true;

      await this.bookFreeEvent({ id: event._id }).catch((err) => {
        this.addNotification({
          message: err.response.data.message,
          icon: "mdi-alert",
          timeout: 3000,
        });
      });

      await this.getMe();

      this.loading = false;
      this.$router.replace({ name: "Calendar" });
      // this.$emit("onLeave");
      // this.selectedOpen = false;
      // this.selectedElement = null;
      // this.selectedEvent = null;
    },

    async bookEventAction(event) {
      this.loading = true;

      await this.bookEvent({ id: event._id }).catch((err) => {
        this.addNotification({
          message: err.response.data.message,
          icon: "mdi-alert",
          timeout: 3000,
        });
      });

      await this.getMe();

      this.loading = false;
      this.$router.replace({ name: "Calendar" });
      // this.$emit("onLeave");
      // this.selectedOpen = false;
      // this.selectedElement = null;
      // this.selectedEvent = null;
    },
  },
  mounted() {
    this.awaitPromiseCallback({
      key: "getMe",
      cb: async () => {
        try {
          await this.getEvents();
        } catch (e) {
          console.log("e: ", e);
        }
      },
    });
  },
  // methods: {
  //     getEventColor(event) {
  //         return event.color
  //     },
  //     setToday() {
  //         this.focus = ''
  //     },
  //     prev() {
  //         this.$refs.calendar.prev()
  //     },
  //     next() {
  //         this.$refs.calendar.next()
  //     },
  //     fetchEvents({ start, end }) {
  //         const events = []

  //         const min = new Date(`${start.date}T00:00:00`)
  //         const max = new Date(`${end.date}T23:59:59`)
  //         const days = (max.getTime() - min.getTime()) / 86400000
  //         const eventCount = this.rnd(days, days + 20)

  //         for (let i = 0; i < eventCount; i++) {
  //             const allDay = this.rnd(0, 3) === 0
  //             const firstTimestamp = this.rnd(min.getTime(), max.getTime())
  //             const first = new Date(firstTimestamp - (firstTimestamp % 900000))
  //             const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
  //             const second = new Date(first.getTime() + secondTimestamp)

  //             console.log(this.rnd())
  //             events.push({
  //                 name: this,
  //                 start: first,
  //                 end: second,
  //                 color: this.colors[this.rnd(0, this.colors.length - 1)],
  //                 timed: !allDay,
  //                 category: this.categories[this.rnd(0, this.categories.length - 1)],
  //             })
  //         }

  //         this.events = events
  //     },
  //     rnd(a, b) {
  //         return Math.floor((b - a + 1) * Math.random()) + a
  //     },
  // },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>

<style scoped>
@media only screen and (min-width: 600px) {
  ::v-deep.v-dialog__content.v-dialog__content--active {
    left: 5.5%;
  }
}

</style>
