<template>
  <div
    class="d-flex flex-wrap video-stream-container mx-auto position-relative"
  >
    <video-item
      muted
      class="local-video-item rounded"
      v-if="localVideoTrack"
      :videoTrack="localVideoTrack"
      :audio-track="localAudioTrack"
      :user="me.email"
    ></video-item>

    <video-call-buttons
      :leave-button="leaveButton"
      class="position-absolute bottom-center w-100 text-center"
      @onLeave="$emit('onLeave')"
    />
  </div>
</template>

<script>
/* eslint-disable */

import VideoItem from "@/components/Video/VideoItem";
import UserMixin from "@/mixins/UserMixin";
import VideoCallButtons from "@/components/Video/VideoCallButtons";
import TwilioMixin from "@/mixins/TwilioMixin";
// import AgoraMixin from "@/mixins/AgoraMixin";

export default {
  name: "preVideoCall",

  props: {
    leaveButton: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    VideoItem,
    VideoCallButtons,
  },

  mixins: [
    // AgoraMixin,
    TwilioMixin,
    UserMixin,
  ],

  async mounted() {
    await this.getOrCreateTracks();
    // console.log("this.$refs.videoItem.$el: ", this.$refs.videoItem.$el);
    // this.$AgoraVideoStream.localVideoTrack.play(this.$refs.videoItem.$el)
    // this.getOrCreateVideoStream();
  },
};
</script>

<style scoped>
.bottom-center {
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.client-not-joined {
  position: absolute;
  bottom: -20%;
  left: 50%;
  transform: translateX(-50%);
}
</style>
