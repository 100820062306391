<template>
  <div>
    <h4 class="text-center mb-4 lh-26px font-weight-medium">
      <v-icon color="warning" left class="me-2">mdi-alert</v-icon>
      {{ $t("Facing difficulties with your call?") }}
    </h4>
    <v-row>
      <v-col cols="12" md="7" class="mx-auto">
        <v-btn
          class="py-6"
          block
          rounded
          color="secondary"
          large
          @click="generateVideoLink"
        >
          {{ $t("Alternative secure call function") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "VideoProblem",
  props: {
    eventId: {
      required: true,
    },
    notifyUserId: {
      required: false,
      default: false,
    },
  },

  methods: {
    generateVideoLink() {
      if (this.notifyUserId) {
        this.$socket.emit("notify:change-video-call", {
          to: this.notifyUserId,
          eventId: this.eventId,
        });
      }

      window.open("https://meet.jit.si/tk-" + this.eventId, "_blank");
    },
  },
};
</script>
