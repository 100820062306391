<template>
  <div>


    <div v-if="isLocal"  class="local">
      <video ref="videoElement" class="rounded-15px" playsinline></video>
    </div>

    <div v-else class="remote">
      <div class="video-item-container">
        <canvas ref="videoCanvasElement" width="1920" height="1080" class="rounded-15px"></canvas>
      </div>
    </div>



    <video-call-buttons-zoom
        v-if="isLocal"

        :leaveButton="true"

        :videoEnabled="videoEnabled"
        :switchCamera="switchCamera"
        :videoTrack="localVideoTrack"
        :activeCamera="activeCamera"
        @toggleVideoActive="onToggleVideoActive"

        :microphoneEnabled="microphoneEnabled"
        :activeMicrophone="activeMicrophone"
        :switchMicrophone="switchMicrophone"
        :audioTrack="localAudioTrack"
        @toggleAudioActive="onToggleAudioActive"

        @onLeave="$emit('onLeave')"
        class="position-absolute bottom-center bottom-20px w-100 text-center"
    />

  </div>

</template>

<script>
/* eslint-disable */

import UserMixin from "@/mixins/UserMixin";
import VideoCallButtonsZoom from "@/components/Video/zoom/VideoCallButtonsZoom.vue";
import ZoomMixin from "@/mixins/ZoomMixin";

export default {
  name: "TestVideoCallZoom",

  mixins: [
    UserMixin,
    ZoomMixin
  ],

  props: {
    // leaveButton: {
    //   type: Boolean,
    //   default: false,
    // },
    isLocal: {},
    user: {}
  },

  components: {
    VideoCallButtonsZoom,
  },

  data() {
    return {
      user_: null,
      interval: null,
      activeCamera: null,
      activeMicrophone: null,
      // localVideoTrack: null,
    };
  },

  computed: {
    videoEnabled() {
      return this?.user_?.bVideoOn
      // return !!this.localVideoTrack?.isVideoStarted
    },

    // activeCamera() {
    //   return this.localVideoTrack?.deviceId
    // },

    microphoneEnabled() {
      return !this?.user_?.muted
      // return !!this.localAudioTrack?.bVideoOn
    },

    // activeMicrophone() {
    //   return this.localAudioTrack?.deviceId
    // }
  },

  methods: {
    async startVideo() {

      if (this.isLocal) {
        // if (this.ZoomStream.isRenderSelfViewWithVideoElement()) {
        await this.ZoomStream.startVideo({videoElement: this.$refs.videoElement})
          // video successfully started and rendered
        // } else {
        //   await this.ZoomStream.startVideo()
        //   await this.ZoomStream.renderVideo(this.$refs.videoCanvasSelfElement, this.ZoomClient.getCurrentUserInfo().userId, 1920, 1080, 0, 0, 3)
        //   // video successfully started and rendered
        // }

        this.$ZoomVideoStream.stream.startAudio();

      } else {
          if(this.user.bVideoOn) {
             await this.ZoomStream.renderVideo(this.$refs.videoCanvasElement, this.user.userId, 1920, 1080, 0, 0, 3)
          }
      }

      this.ZoomClient.on('peer-video-state-change', (payload) => {
        if (payload.userId === this.user.userId) {
          if (payload.action === 'Start') {
            this.ZoomStream.renderVideo(this.$refs.videoCanvasElement, payload.userId, 1920, 1080, 0, 0, 3)
          } else if (payload.action === 'Stop') {
            this.ZoomStream.stopRenderVideo(this.$refs.videoCanvasElement, payload.userId)
          }
        }

      })

      this.ZoomClient.on('video-capturing-change', (payload) => {
          this.user_ = this.ZoomClient.getUser(this.user.userId)

            // try {
            //   if (payload.state === 'Started') {
            //     console.log('Capture started');
            //   } else if (payload.state === 'Stopped') {
            //     console.log('Capture stopped');
            //   } else {
            //     console.log('Stop capturing Failed');
            //   }
            // } catch (error) {
            //   console.log(error);
            // }
      });


      // this.localVideoTrack.start(document.querySelector('#preview-camera-video'))
    },

    async switchCamera(cameraId) {
      this.ZoomStream.switchCamera(cameraId)
    },

    async onToggleVideoActive() {
      if (this.videoEnabled) {
        await this.ZoomStream.stopVideo()
      } else {
        await this.ZoomStream.startVideo({videoElement: this.$refs.videoElement})
      }
    },

    // async startAudio() {
    //   // await this.localAudioTrack.start()
    //   // await this.localAudioTrack.unmute()
    // },

    async onToggleAudioActive() {

      if (this.microphoneEnabled) {
        await this.ZoomStream.muteAudio()
      } else {
        await this.ZoomStream.unmuteAudio()
      }
    },

    async switchMicrophone(microphoneId) {
      this.ZoomStream.switchMicrophone(microphoneId)
      //   await this.localAudioTrack.stop()
      //
      //   await this.ZoomVideoStream.setLocalAudioTrack(microphoneId);
      //
      //   await this.startAudio()
      // }
    }
  },

  beforeDestroy() {
    clearInterval(this.interval)
    this.ZoomStream.stopVideo()
    this.ZoomStream.stopAudio()
    // this.localVideoTrack.stop()
    // this.localAudioTrack.stop()
  },


  async mounted() {
    this.interval = setInterval(() => {
      this.user_ = this.ZoomClient.getUser(this.user.userId)
      this.activeCamera = this.ZoomStream.getActiveCamera()
      this.activeMicrophone = this.ZoomStream.getActiveMicrophone()
    }, 1000)

    await this.ZoomVideoStream.getVideoDevices();
    await this.ZoomVideoStream.getAudioDevices();
    // await this.ZoomVideoStream.setLocalVideoTrack();
    // await this.ZoomVideoStream.setLocalAudioTrack();

    await this.startVideo()
    // await this.startAudio()
  },
};
</script>

<style scoped lang="scss">
video {
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
}
.local {
  display: flex;
  border-radius: 15px;
  video {
    background: #c7c7c7;
  }

}
.bottom-20px {
  bottom: 20px;
}
.remote {
  canvas {
    background: #c7c7c7;
    aspect-ratio: 16/9;
    width: 100%;
  }
}
</style>