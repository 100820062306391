<template>
  <div class="fill-height">
    <!-- <v-row class="d-flex fill-height align-center justify-space-between">
      <v-col cols="12" md="6">
        <PreVideoCall style="width: 100%" />

      </v-col>
    </v-row> -->

    <BrowserAlert />
    <div v-if="loading" class="py-16 text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <template v-else>
      <!-- TODO: Already joining the room  -->
      <v-row
        v-if="joinTime"
        class="d-flex fill-height align-center justify-space-between"
      >
        <!-- <template v-if="showTherapistNotJoinedText && !canClientJoin">-->
        <!--          <v-col class="mx-auto text-center" cols="12" md="6">-->
        <!--            <h3 class="headline font-weight-bold mb-10 text-center">-->
        <!--              Uh-Oh! It looks like your therapist will not be able to attend-->
        <!--              your upcoming session-->
        <!--            </h3>-->

        <!--            <h4 class="title mb-10 text-center">-->
        <!--              We apologize for any inconvenience this may have caused, we know-->
        <!--              this might be upsetting for you. As part of our policy, your live-->
        <!--              session will be credited back into your account. Please feel free-->
        <!--              to book another session at your earliest convenience.-->
        <!--            </h4>-->

        <!--            <v-btn-->
        <!--              color="secondary"-->
        <!--              large-->
        <!--              :to="{ name: 'Calendar' }"-->
        <!--              v-if="!isTherapist"-->
        <!--            >-->
        <!--              {{ $t("Book a Session") }}-->
        <!--            </v-btn>-->
        <!--          </v-col>-->
        <!--        </template> -->
        <template>
          <v-col cols="12" md="6">
            <TestVideoCallZoom />
<!--            <PreVideoCall style="width: 100%" />-->
          </v-col>

          <v-col cols="12" md="6">
            <div class="d-flex flex-column">
              <template v-if="!canStartCall">
                <h3 class="headline mb-5 text-center">
                  Your Next Session is Scheduled for
                </h3>
                <p
                  class="subtitle text-center font-weight-medium primary--text"
                >
                  {{ nextSessionStartDate | moment("DD - MMM - YYYY LT") }}
                </p>
              </template>

              <h3 class="mb-4 text-center lh-26px fs-26px font-weight-bold">
                {{ $t("Ready to join?") }}
              </h3>

              <template v-if="!isTherapist">
                <p class="fs-20px mb-10 lh-26px text-center">
                  {{ $t("Please Wait Until Your Therapist Join the Call") }}
                </p>
                <h4
                  class="text-center mb-4 lh-26px font-weight-medium"
                  v-if="!canJoin"
                >
                  <v-icon class="left me-2" color="danger"
                    >mdi-account-cancel
                  </v-icon>
                  {{ $t("Your therapist will join the call soon") }}
                </h4>
                <h4 class="text-center mb-4 lh-26px font-weight-medium" v-else>
                  <v-icon class="left me-2" color="success"
                    >mdi-account-check
                  </v-icon>
                  {{ $t("Your Therapist Joined the call") }}
                </h4>
              </template>

              <v-row>
                <v-col cols="12" md="10" class="mx-auto" v-if="isTherapist">
                  <h4
                    class="fs-20px mb-4 lh-26px text-center font-weight-medium"
                  >
                    Please join this call to grant your client permission to do
                    the same
                  </h4>
                </v-col>
                <v-col cols="12" md="7" class="mx-auto">
                  <v-btn
                    :color="canJoin && canStartCall ? 'secondary' : 'accent'"
                    :outlined="!(canJoin && canStartCall)"
                    block
                    rounded
                    class="py-6"
                    :disabled="!(canJoin && canStartCall)"
                    exact
                    :to="{
                      name: 'VideoCallRoom',
                      params: { id: nextSession._id || null },
                    }"
                  >
                    {{ $t("Join") }}
                  </v-btn>
                </v-col>
              </v-row>

              <VideoProblem
                :eventId="nextSession._id || ''"
                :notifyUserId="myTherapistId || nextSession.client"
              />
            </div>
            <CalendarEventsVideoActions :currentEvent="nextSession" />
          </v-col>

          <!-- <v-col cols="12" md="6">

          </v-col> -->
        </template>
      </v-row>

      <!-- TODO: Add if no session booked  -->
      <div
        v-else-if="!nextSession"
        class="d-flex flex-column fill-height align-center py-15"
      >
        <img
          src="./../../../assets/schedule.png"
          alt="Book a session"
          width="300"
        />
        <h2 class="mt-10">{{ $t("Ooops!") }}</h2>
        <h3 class="mb-8 mt-4 font-weight-medium">
          {{ $t("You have no sessions booked yet") }}
        </h3>
        <v-col cols="8" md="3" xl="2">
          <v-btn
            block
            color="secondary"
            large
            class="mb-2"
            :to="{ name: 'Calendar' }"
            v-if="!isTherapist"
          >
            {{ $t("Book a Session") }}
          </v-btn>
          <TestYourVideoZoom />

        </v-col>
      </div>

      <!-- TODO: Add if next session exists  -->
      <div
        v-else-if="nextSession"
        class="d-flex flex-column fill-height align-center justify-center py-15"
      >
        <img
          src="./../../../assets/schedule.png"
          alt="Book a session"
          width="300"
        />
        <h2 class="mt-10">{{ $t("Your next session is scheduled for:") }}</h2>
        <h3 class="mb-8 mt-4 font-weight-medium secondary--text">
          {{ nextSessionStartDate | moment("DD/MM/YYYY LT") }}
        </h3>
        <v-col cols="8" md="3" xl="2">
          <v-btn
            block
            color="secondary"
            large
            class="mb-2"
            :to="{ name: 'Calendar' }"
            v-if="!isTherapist"
          >
            {{ $t("Book another Session") }}
          </v-btn>

          <TestYourVideoZoom />

        </v-col>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import CalendarEventsVideoActions from "@/components/Video/CalendarEventsVideoActions";
import VideoProblem from "@/components/Video/VideoProblem";
import TestYourVideo from "@/components/Video/TestYourVideo";
import BrowserAlert from "@/components/BrowserAlert";
import { mapActions } from "vuex";
import UserMixin from "@/mixins/UserMixin";
import moment from "moment";
import PreVideoCall from "@/components/Video/PreVideoCall";
// import AgoraMixin from "@/mixins/AgoraMixin";
import TwilioMixin from "@/mixins/TwilioMixin";
import therapistMixin from "@/mixins/TherapistMixin";
import TestVideoCallZoom from "@/components/Video/zoom/TestVideoCallZoom.vue";
import TestYourVideoZoom from "@/components/Video/zoom/TestYourVideoZoom.vue";
import ZoomMixin from "@/mixins/ZoomMixin";
// import VideoMixin from "@/mixins/VideoMixin";

export default {
  name: "VideoCallBase",

  beforeRouteLeave(to, from, next) {
    if (to.name !== "VideoCallRoom") {
      this.ZoomVideoStream.leave()
      // if (this.localVideoTrack) {
      // }
    }

    next();
  },

  components: {
    TestVideoCallZoom,
    TestYourVideoZoom,
    CalendarEventsVideoActions,
    VideoProblem,

    // TestYourVideo,
    // PreVideoCall,
    BrowserAlert,
  },

  mixins: [
      ZoomMixin,
    // VideoMixin,
    // AgoraMixin,
    TwilioMixin,
    UserMixin,
    therapistMixin,
  ],

  data: () => {
    return {
      nextSession: null,
      loading: false,
      // canClientJoin: false,
      // showTherapistNotJoinedText: false,
    };
  },

  computed: {
    joinTime() {
      if (!this.nextSessionStartDate) return false;
      return moment().add(2, "hours").isAfter(this.nextSession.eventStartDate);
    },

    canStartCall() {
      return moment().add(1, "hours").isAfter(this.nextSession.eventStartDate);
    },

    nextSessionStartDate() {
      return this.nextSession?.eventStartDate;
    },

    canJoin() {
      if (this.isTherapist) {
        return true;
      } else {
        return this.canClientJoin;
      }
    },

    canClientJoin() {
      return this.zoomAllUsers.length > 1;
    },
  },

  methods: {
    ...mapActions("calendar", [
      "getNextSession",
      "notJoined",
      // "getAgoraToken",
      // "getTwilioToken",
    ]),

  },

  mounted() {
    this.loading = true;

    this.awaitPromiseCallback({
      key: "getMe",
      cb: async () => {
        this.nextSession = await this.getNextSession();

        this.loading = false;

        if (this.joinTime && !this.isTherapist) {

          await this.getAccessToken(this.nextSession._id)
          await this.ZoomVideoStream.joinRoom(this.nextSession._id, this.getUserName(this.me))

        }
      },
    });
  },

  async beforeDestroy() {
  // if (this.showTherapistNotJoinedText && !this.canClientJoin && this.nextSession) {
  //   await this.notJoined({ id: this.nextSession._id });
  // }
  },
};
</script>

<style scoped></style>
